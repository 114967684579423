class Game {
    constructor(playerHeroes, npcHeroes) {
        this.playerHeroes = playerHeroes; // Массив героев игрока
        this.npcHeroes = npcHeroes; // Массив NPC
    }

    async playRound() {
        console.log(`Новый раунд!`);
        window.dispatchEvent(new CustomEvent('roundStartEvent'));
        await this.playerTurn();
        await this.npcTurn();
        window.dispatchEvent(new CustomEvent('roundEndEvent'));

        if (!this.hasAliveHeroes(this.playerHeroes) || !this.hasAliveHeroes(this.npcHeroes)) {
            this.endGame();
        }
    }

    hasAliveHeroes(heroes) {
        return heroes.some(hero => hero.isAlive());
    }

    async playerTurn() {
        for (const playerHero of this.playerHeroes) {
            if (playerHero.isAlive()) {
                if (!playerHero.getEnemyTarget().isAlive()) {
                    // Устанавливаем цель на первого живого NPC
                    const firstAliveNpc = this.npcHeroes.find(npc => npc.isAlive());
                    playerHero.setEnemyTarget(firstAliveNpc);
                }

                const $target = playerHero.getEnemyTarget();
                if ($target) {
                    const damage = playerHero.performAttack();
                    console.log(`${playerHero.name} атакует ${$target.name} на ${damage} урона.`);
                }
                await this.delay(2000); // Задержка после хода героя
            }
        }
    }

    async npcTurn() {
        for (const npc of this.npcHeroes) {
            if (npc.isAlive()) {
                // Выбор случайного живого героя игрока
                const alivePlayerHeroes = this.playerHeroes.filter(hero => hero.isAlive());
                if (alivePlayerHeroes.length > 0) {
                    const index = Math.floor(Math.random() * alivePlayerHeroes.length);
                    const randomPlayer = alivePlayerHeroes[index];
                    if (randomPlayer) {
                        npc.setEnemyTarget(randomPlayer);
                        const damage = npc.performAttack();
                        console.log(`${npc.name} атакует ${randomPlayer.name} на ${damage} урона.`);
                    }
                    await this.delay(2000); // Задержка после хода NPC
                }
            }
        }
    }

    endGame() {
        const playerHeroes = this.playerHeroes;
        const npcHeroes = this.npcHeroes;

        const aliveHero = playerHeroes.filter(hero => hero.isAlive()).length === 0 ? npcHeroes[0] : playerHeroes[0];
        const event = new CustomEvent('gameOverEvent', { detail: { aliveHero: aliveHero } });
        window.dispatchEvent(event);
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms)); // Функция задержки
    }
}

export default Game;
