import React, {useState} from 'react';
import GridHeroCard from './GridHeroCard';
import AttackButton from './AttackButton';
import {PlayerHero, NpcHero} from '../models/Hero';
import RoundInfo from './RoundInfo';
import Sound from '../components/Sound';
import Game from '../models/Game';

// Создание героев с уникальными ID
const playerHeroes = [
    new PlayerHero(1, "Лира", 5, 20, 2, 'images/heroes/lira-id-1.png'),
    new PlayerHero(2, "Калеб", 3, 25, 1, 'images/heroes/kaleb-id-2.png'),
    new PlayerHero(3, "Серафина", 4, 15, 3, 'images/heroes/serafina-id-3.png')
];

const npcHeroes = [
    new NpcHero(4, "Терон", 4, 18, 2, 'images/heroes/teron-id-1.png'),
    new NpcHero(5, "Элиза", 6, 20, 1, 'images/heroes/eliza-id-2.png'),
    new NpcHero(6, "Гром", 5, 22, 3, 'images/heroes/grom-id-3.png')
];

// Устанавливаем цель для атаки
playerHeroes.forEach(hero => hero.setEnemyTarget(npcHeroes[0]));

const MainArea = () => {
    const [heroesState, setHeroes] = useState({
        players: playerHeroes,
        npcs: npcHeroes
    });

    const [game, setGame] = useState(new Game(playerHeroes, npcHeroes));
    const [gameOver, setGameOver] = useState(false);

    const onAttack = async () => {
        if (!gameOver) {
            await game.playRound();
            setHeroes({
                players: playerHeroes,
                npcs: npcHeroes
            });

            if (!game.hasAliveHeroes(playerHeroes) || !game.hasAliveHeroes(npcHeroes)) {
                setGameOver(true);
            }
        }
    };

    return (
        <div className="battle-container">
            <h1 style={{paddingTop: '20px'}}>Бои</h1>
            <GridHeroCard
                heroesState={heroesState}
            />
            <div className="button-container">
                <AttackButton onAttack={onAttack}/>
                <Sound/>
            </div>
            <RoundInfo/>
            {gameOver && (
                <div>
                    Игра окончена!
                </div>
            )}
        </div>
    );
};

export default MainArea;
