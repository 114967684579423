class Hero {
    constructor(id, name, attack, health, speed, imageUrl) {
        this.id = id; // Уникальный идентификатор
        this.name = name;
        this.attack = attack;
        this.health = health;
        this.speed = speed;
        this.imageUrl = imageUrl;
        this.target = null; // Новое свойство для хранения цели
    }

    isAlive() {
        return this.health > 0;
    }

    attackEnemy() {
        if (this.isAlive() && this.target && this.target.isAlive()) {
            this.target.takeDamage(this.attack);
            return this.attack;
        }
        return 0; // Возвращаем 0, если атака не была выполнена
    }

    takeDamage(damage) {
        this.health -= damage;
    }

    performAttack() {
        if (this.isAlive() && this.target) {//TODO дублирование условия с attackEnemy
            const damage = this.attackEnemy();
            window.dispatchEvent(
                new CustomEvent('heroAttackEvent', { detail: { hero: this, enemy: this.target, damage: damage } })
            );
            if (!this.target.isAlive()) {
                window.dispatchEvent(new CustomEvent('heroDieEvent', { detail: { hero: this.target } }));
            }

            return damage;
        }

        return 0;
    }

    getEnemyTarget() {
        return this.target;
    }

    setEnemyTarget(enemy) {
        this.target = enemy; // Устанавливаем цель для атаки
    }
}

class PlayerHero extends Hero {
    constructor(id, name, attack, health, speed, imageUrl) {
        super(id, name, attack, health, speed, imageUrl);
    }

    isNpc() {
        return false;
    }
}

class NpcHero extends Hero {
    constructor(id, name, attack, health, speed, imageUrl) {
        super(id, name, attack, health, speed, imageUrl);
    }

    isNpc() {
        return true;
    }
}

// Экспортируем классы
export { Hero, PlayerHero, NpcHero };
