import React, { useEffect, useRef, useState } from 'react';
import { useHover } from './HoverContext';
import '../styles/HeroCard.css';
import useHeroCommonEvents from './useHeroCommonEvents';
import useAttackHighlight from './useAttackHighlight';
import { motion } from 'framer-motion';
import DamageIndicator from './DamageIndicator';

const PlayerHeroCard = ({ hero }) => {
    const heroRef = useRef(null); // Создаем реф для доступа к элементу
    const { setIsPlayerHovered, setSelectedEnemyId } = useHover();
    const [attackHeroId, setAttackHeroId] = useState(null);
    const [attackedHeroId, setAttackedHeroId] = useState(null);
    const [damage, setDamage] = useState(null);

    const handleDamage = () => {
        // Устанавливаем значение урона, например, 50
        setDamage(-50);

        // Сбрасываем значение урона через 1 секунду, чтобы убрать индикатор
        setTimeout(() => {
            setDamage(null);
        }, 1000);
    };

    // Используем пользовательский хук для обработки событий
    useHeroCommonEvents(heroRef, hero, setAttackHeroId, setAttackedHeroId);

    useEffect(() => {
        const heroElement = heroRef.current;

        const handleDragStart = (event) => {
            event.dataTransfer.setData('text/plain', hero.id);
        };

        // Добавляем обработчики событий
        heroElement.addEventListener('dragstart', handleDragStart);

        // Удаляем обработчики событий при размонтировании компонента
        return () => {
            heroElement.removeEventListener('dragstart', handleDragStart);
        };
    }, [hero]);

    // Используем хук для определения выделения
    const { className } = useAttackHighlight(attackedHeroId, attackHeroId, hero.id);

    return (
        <motion.div
            whileHover={{scale: 1.1}} // Увеличение при наведении
            transition={{type: 'spring', stiffness: 300}}
            onMouseEnter={() => {
                setIsPlayerHovered(true);
                setSelectedEnemyId(hero.getEnemyTarget().id); // Устанавливаем ID героя при наведении
            }}
            onMouseLeave={() => {
                setIsPlayerHovered(false);
                setSelectedEnemyId(null); // Сбрасываем ID героя при уходе мыши
            }}
            onClick={handleDamage}
            className={`hero ${className}`}
            data-id={hero.id}
            data-attack={hero.attack}
            data-health={hero.health}
            data-speed={hero.speed}
            draggable="true" ref={heroRef}
        >
            <img src={hero.imageUrl} alt={hero.name}/>
            <p className="name">{hero.name}</p>
            <div className="attack">{hero.attack}</div>
            <div className="health">{hero.health}</div>
            {damage !== null && <DamageIndicator damage={damage}/>}
        </motion.div>
    );
};

export default PlayerHeroCard;