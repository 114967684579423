import React, {useEffect, useRef, useState } from 'react';
import '../styles/AttackButton.css'; // Импортируем стили для кнопки

const AttackButton = ({ onAttack }) => {
    const btnRef = useRef(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    useEffect(() => {
        const handleRoundStart = () => {
            setIsButtonDisabled(true);
        };

        const handleRoundEnd = () => {
            setIsButtonDisabled(false);
        };
        // Добавляем обработчики событий
        window.addEventListener('roundEndEvent', handleRoundEnd);
        window.addEventListener('roundStartEvent', handleRoundStart);

        // Удаляем обработчики событий при размонтировании компонента
        return () => {
            window.removeEventListener('roundEndEvent', handleRoundEnd);
            window.addEventListener('roundStartEvent', handleRoundStart);
        };
    });

  return (
      <button ref={btnRef}
              className={`attack-button ${isButtonDisabled ? 'disabled' : ''}`}
              onClick={onAttack} disabled={isButtonDisabled}>

          {isButtonDisabled ? 'Ожидание...' : '🔥 В бой!'}
      </button>
  );
};

export default AttackButton;