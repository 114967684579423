import React, { useState } from 'react';
import './styles/reset.css';
import './styles/App.css';
import Battle from './pages/Battle';
import Clicker from './pages/Clicker';
import Tavern from './pages/Tavern';

const App = () => {
    const [currentPage, setCurrentPage] = useState('Battle');

    const renderPage = () => {
        switch (currentPage) {
            case 'Battle':
                return <Battle />;
            case 'Clicker':
                return <Clicker />;
            case 'Tavern':
                return <Tavern />;
            default:
                return <Battle />;
        }
    };

    return (
        <div>
            <div>
                {renderPage()}
            </div>
            <nav className="fixed-nav"> {/* Фиксированное положение навигации */}
                <button className="attack-button2" onClick={() => setCurrentPage('Battle')}>
                    Бои
                </button>
                <button className="attack-button2"
                        onClick={() => setCurrentPage('Clicker')}>Кликер
                </button>
                <button className="attack-button2"
                        onClick={() => setCurrentPage('Tavern')}>Таверна
                </button>
            </nav>
        </div>
    );
};

export default App;
