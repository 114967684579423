import React, { useEffect, useState, useRef } from 'react';

const Sound = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const bgSound = useRef(null); // Используем useRef для хранения объекта Audio

    useEffect(() => {
        // Создаем объект Audio
        bgSound.current = new Audio('/sound/Echoes of Ireland.mp3');

        // Загрузка звуков
        const roundStartSound = new Audio('/sound/round-start.mp3');
        const attackSound1 = new Audio('/sound/attack-sound-1.mp3');
        const attackSound2 = new Audio('/sound/attack-sound-2.mp3');
        const deadHeroSound = new Audio('/sound/dead-hero.mp3');
        const winSound = new Audio('/sound/win.mp3');
        const lossSound = new Audio('/sound/loss.mp3');

        const stopMusic = () => {
            try {
                bgSound.current.pause(); // Останавливаем музыку
                bgSound.current.currentTime = 0; // Сбрасываем время на начало
                setIsPlaying(false); // Переключаем состояние
            } catch (error) {
                console.error("Ошибка при остановке музыки:", error);
            }
        };

        const playRoundStartSound = () => {
            stopMusic();
            roundStartSound.play();
        };

        const playAttackSound = (event) => {
            if (event.detail.hero.isNpc()) {
                attackSound1.play();
            } else {
                attackSound2.play();
            }
        };

        const playDeadHeroSound = () => deadHeroSound.play();

        const playGameOver = (event) => {
            event.detail.aliveHero.isNpc() ? lossSound.play() : winSound.play();
        };

        // Добавление обработчиков событий
        window.addEventListener('roundStartEvent', playRoundStartSound);
        window.addEventListener('heroAttackEvent', playAttackSound);
        window.addEventListener('heroDieEvent', playDeadHeroSound);
        window.addEventListener('gameOverEvent', playGameOver);

        // Очистка обработчиков событий при размонтировании компонента
        return () => {
            window.removeEventListener('roundStartEvent', playRoundStartSound);
            window.removeEventListener('heroAttackEvent', playAttackSound);
            window.removeEventListener('heroDieEvent', playDeadHeroSound);
            window.removeEventListener('gameOverEvent', playGameOver);
            bgSound.current.pause();
            bgSound.current.currentTime = 0;
        };
    }, []); // Пустой массив зависимостей, чтобы выполнить только один раз при монтировании

    const toggleMusic = () => {
        try {
            console.log("Текущая музыка:", isPlaying);
            if (isPlaying) {
                console.log("Остановка музыки");
                bgSound.current.pause(); // Останавливаем музыку
                bgSound.current.currentTime = 0; // Сбрасываем время на начало
            } else {
                console.log("Запуск музыки");
                bgSound.current.play(); // Запускаем музыку
            }
            setIsPlaying(!isPlaying); // Переключаем состояние
        } catch (error) {
            console.error("Ошибка при переключении музыки:", error);
        }
    };

    return (
        <div>
            <button onClick={toggleMusic} className='attack-button'>
                {isPlaying ? (
                    <span>
                        <span className="material-icons">volume_off</span> {/* Иконка для остановки музыки */}
                    </span>
                ) : (
                    <span>
                        <span className="material-icons">volume_up</span> {/* Иконка для включения музыки */}
                    </span>
                )}
            </button>
        </div>
    );
};

export default Sound;
