import React, {useEffect, useRef, useState} from 'react';
import '../styles/HeroCard.css';
import { useHover } from './HoverContext';
import useHeroCommonEvents from "./useHeroCommonEvents";
import useAttackHighlight from './useAttackHighlight';

const NpcHeroCard = ({ hero, playerHeroes }) => {
    const heroRef = useRef(null); // Создаем реф для доступа к элементу
    const { isPlayerHovered, selectedEnemyId } = useHover();
    const [attackHeroId, setAttackHeroId] = useState(null);
    const [attackedHeroId, setAttackedHeroId] = useState(null);

    // Используем пользовательский хук для обработки событий
    useHeroCommonEvents(heroRef, hero, setAttackHeroId, setAttackedHeroId);

    useEffect(() => {
        const heroElement = heroRef.current;

        const handleDragStart = (event) => {
            event.preventDefault(); // Предотвращаем действие по умолчанию
        };

        const handleDragOver = (event) => {
            event.preventDefault(); // Позволяет сбросить элемент
        }

        const handleDrop = (event) => {
            event.preventDefault();
            const heroId = event.dataTransfer.getData('text/plain');
            const playerHero = playerHeroes[heroId - 1];
            playerHero.setEnemyTarget(hero);
        }

        heroElement.addEventListener('dragover', handleDragOver);
        heroElement.addEventListener('drop', handleDrop);
        heroElement.addEventListener('dragstart', handleDragStart);

        // Удаляем обработчики событий при размонтировании компонента
        return () => {
            heroElement.removeEventListener('dragover', handleDragOver);
            heroElement.removeEventListener('drop', handleDrop);
            heroElement.removeEventListener('dragstart', handleDragStart);
        };
    }, [hero]); // Зависимость от hero, чтобы обновлять обработчики при изменении героя

    // Используем хук для определения выделения
    let { className } = useAttackHighlight(attackedHeroId, attackHeroId, hero.id);

    if (isPlayerHovered && selectedEnemyId === hero.id) {
        className = 'highlight';
    }

    return (
        <div
             className={`hero ${className}`}
             data-id={hero.id} data-attack={hero.attack} data-health={hero.health}
             data-speed={hero.speed} draggable="false" ref={heroRef}>
            <img src={hero.imageUrl} alt={hero.name} />
            <p className="name">{hero.name}</p>
            <div className="attack">{hero.attack}</div>
            <div className="health">{hero.health}</div>
        </div>
    );
};

export default NpcHeroCard;
