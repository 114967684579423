import React, { createContext, useContext, useState } from 'react';

const HoverContext = createContext();

export const HoverProvider = ({ children }) => {
    const [isPlayerHovered, setIsPlayerHovered] = useState(false);
    const [selectedEnemyId, setSelectedEnemyId] = useState(null); // Состояние для хранения ID героя

    return (
        <HoverContext.Provider value={{ isPlayerHovered, setIsPlayerHovered, selectedEnemyId, setSelectedEnemyId }}>
            {children}
        </HoverContext.Provider>
    );
};

export const useHover = () => {
    return useContext(HoverContext);
};
