import React from 'react';
import { motion } from 'framer-motion';

const DamageIndicator = ({ damage }) => {
    return (
        <motion.div
            initial={{ y: 0, opacity: 1 }} // Начальное состояние
            animate={{ y: -70, opacity: 0 }} // Конечное состояние
            transition={{ duration: 0.7 }} // Параметры перехода
            style={{
                position: 'absolute',
                top: '50%', // Центрируем по вертикали
                left: '50%', // Центрируем по горизонтали
                transform: 'translate(-50%, -50%)', // Сдвигаем на половину ширины и высоты
                color: 'red',
                fontSize: '24px',
                fontWeight: 'bold',
                pointerEvents: 'none', // Чтобы не мешать взаимодействию с другими элементами
            }}
        >
            {damage}
        </motion.div>
    );
};

export default DamageIndicator;
