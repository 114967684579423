import React, { useEffect, useState } from 'react';

const RoundInfo = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const handleDieHero = (event) => {
            const message = `${event.detail.hero.name} погиб!`;
            setMessages(prevMessages => [message]);
        };

        const handleGameOver = (event) => {
            const message = event.detail.aliveHero.isNpc() ? "NPC победил!" : "Игрок победил!";
            setMessages(prevMessages => [message]);
        };

        const handleHeroAttack = (event) => {
            const message = `${event.detail.hero.name} атакует ${event.detail.enemy.name} на ${event.detail.damage} урона.`;
            setMessages(prevMessages => [message]);
        };

        const handleRoundStar = (event) => {
            setMessages('');
        };

        // Добавляем слушатель события
        window.addEventListener('roundStartEvent', handleRoundStar);
        window.addEventListener('heroDieEvent', handleDieHero);
        window.addEventListener('gameOverEvent', handleGameOver);
        window.addEventListener('heroAttackEvent', handleHeroAttack);

        // Убираем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('heroDieEvent', handleDieHero);
            window.removeEventListener('gameOverEvent', handleGameOver);
            window.removeEventListener('heroAttackEvent', handleHeroAttack);
        };
    }, []);

    return (
        <div id="round-info">
            {messages.map((message, index) => (
                <div key={index}>{message}</div>
            ))}
        </div>
    );
};

export default RoundInfo;
