import { useMemo } from 'react';

const useAttackHighlight = (attackedHeroId, attackHeroId, heroId) => {
    return useMemo(() => {
        const isHighlight = attackedHeroId === heroId;
        const isHighlightGreen = attackHeroId === heroId;

        let className = '';
        if (isHighlightGreen) {
            className = 'highlight-green';
        } else if (isHighlight) {
            className = 'highlight';
        }

        return { className };
    }, [attackedHeroId, attackHeroId, heroId]);
};

export default useAttackHighlight;
