import React from 'react';
import { HoverProvider } from './HoverContext';
import PlayerHeroCard from './PlayerHeroCard';
import NpcHeroCard from './NpcHeroCard';
import '../styles/HeroCardGrid.css';

const GridHeroCard = ({heroesState}) => {
    return (
        <div className="hero-card-grid">
            <HoverProvider>
                <div className="npc-heroes">
                    {heroesState.npcs.filter(hero => hero.isAlive()).map((hero) => (
                        <NpcHeroCard
                            key={`npc-${hero.id}`}
                            hero={hero}
                            playerHeroes={heroesState.players}
                        />
                    ))}
                </div>
                <h2>⚔️</h2>
                <div className="player-heroes">
                    {heroesState.players.filter(hero => hero.isAlive()).map((hero) => {
                        return (
                            <PlayerHeroCard
                                key={`player-${hero.id}`}
                                hero={hero}
                            />
                        );
                    })}
                </div>
            </HoverProvider>
        </div>
    );
};

export default GridHeroCard;
