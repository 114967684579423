import React from 'react';
import '../styles/App.css'; // Импортируем CSS файл

const Tavern = () => {
    return <div className="tavern-container">
        <h1 style={{paddingTop: '20px'}}>Таверна</h1>
    </div>
};

export default Tavern;
