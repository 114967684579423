import { useEffect } from 'react';

const useHeroCommonEvents = (heroRef, hero, setAttackHeroId, setAttackedHeroId) => {
    useEffect(() => {
        const heroElement = heroRef.current;

        const handleHeroAttack = (event) => {
            setAttackHeroId(event.detail.hero.id);
            setAttackedHeroId(event.detail.hero.getEnemyTarget().id);
        };

        const handleDieHero = (event) => {
            if (event.detail.hero.id === hero.id) {
                heroElement.style.filter = 'grayscale(100%)';
            }
        };

        const handleRoundEnd = () => {
            heroElement.classList.remove('highlight');
            heroElement.classList.remove('highlight-green');
            setAttackHeroId(null);
            setAttackedHeroId(null);
        };

        // Добавляем обработчики событий
        window.addEventListener('heroAttackEvent', handleHeroAttack);
        window.addEventListener('roundEndEvent', handleRoundEnd);
        window.addEventListener('heroDieEvent', handleDieHero);

        // Удаляем обработчики событий при размонтировании компонента
        return () => {
            window.removeEventListener('heroAttackEvent', handleHeroAttack);
            window.removeEventListener('roundEndEvent', handleRoundEnd);
            window.removeEventListener('heroDieEvent', handleDieHero);
        };
    }, [hero, heroRef, setAttackHeroId, setAttackedHeroId]); // Зависимости
};

export default useHeroCommonEvents;
